import type { StateDeclarationFn } from 'controller/types';

export default (function (vm) {
  return {
    name: 'my-groups',
    parent: 'tpa',
    url: '',
    abstract: true,
    resolve: [
      {
        token: 'user',
        async resolveFn() {
          return vm.application$.fetchUserProfile().unwrap();
        },
      },
    ],
  };
} as StateDeclarationFn);
